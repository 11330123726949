import { useEffect, useState } from 'react';
import { Button, Col, Form, Table } from 'react-bootstrap';
// import { Link } from "react-router-dom";
import { IMonthlySummaryBreakdownItem } from '../../../../../redux/features/reporting/IMonthlySummaryData';
import { floatVal, Hash, HashOf, mapKeys } from '@jamesgmarks/utilities';
import {DownloadCSV} from '../../../../parts/DownloadCSV';
import { roundFloatToCentsAsDollar } from '@hydra/internal';

interface IClientStreamMonthlyBreakdownItem {
  clientName: string;
  revenueStream: string;
  partnerRevenue: number;
  lwsRevenue: number;
  subTotal: number;
  taxes: number;
  total: number;
  discountedTotal: number;
  finalProratedTotal: number;
}

export const MonthlyClientBreakdown = ({
  subscriptionBreakdown,
  currencyCode,
}: {
  subscriptionBreakdown: IMonthlySummaryBreakdownItem[],
  currencyCode?: string,
}) => {
  const [filters, setFilters] = useState({} as Hash);
  const [filteredBreakdown, setFilteredBreakdown] = useState(subscriptionBreakdown);

  const [ totals, setTotals ] = useState({
    partnerRevenue: 0,
    lwsRevenue: 0,
    subTotal: 0,
    discountedTotal: 0,
    proratedTotal: 0,
    taxes: 0,
    total: 0,
  });

  const clientTotals: HashOf<HashOf<IClientStreamMonthlyBreakdownItem>> = {};
  const clientTotalsList: IClientStreamMonthlyBreakdownItem[] = [];

  // useEffect(() => {
  filteredBreakdown.forEach((fbd) => {
    if (!clientTotals[fbd.clientId]) clientTotals[fbd.clientId] = {};
    if (!clientTotals[fbd.clientId][fbd.revenueStream]) clientTotals[fbd.clientId][fbd.revenueStream] = {
      clientName: fbd.clientName,
      revenueStream: fbd.revenueStream,
      partnerRevenue: 0,
      lwsRevenue: 0,
      subTotal: 0,
      taxes: 0,
      total: 0,
      discountedTotal: 0,
      finalProratedTotal: 0,
    };
    const existingClient = clientTotals[fbd.clientId] ?? {};
    const existingPartnerData = existingClient[fbd.revenueStream] ?? {};
    const total = existingPartnerData.total + fbd.total;
    const discountedItemPrice = (fbd.subTotal - (fbd.subTotal * fbd.discountPercentage));
    const discountedTotal = existingPartnerData.discountedTotal + discountedItemPrice;
    const finalProratedTotal = (
      existingPartnerData.finalProratedTotal
        + (discountedItemPrice * fbd.prorationPercentage)
    );
    const newData = {
      clientName: fbd.clientName,
      revenueStream: fbd.revenueStream,
      partnerRevenue: existingPartnerData.partnerRevenue + (fbd.partnerRevenue ?? 0),
      lwsRevenue: existingPartnerData.lwsRevenue + fbd.lwsRevenue,
      subTotal: existingPartnerData.subTotal + fbd.subTotal,
      discountedTotal,
      finalProratedTotal,
      taxes: existingPartnerData.taxes + floatVal(fbd.taxLineData),
      total,
    } as IClientStreamMonthlyBreakdownItem;
    clientTotals[fbd.clientId][fbd.revenueStream] = newData;
  });
  mapKeys(clientTotals, (clientId) => {
    mapKeys(clientTotals[clientId], (streamName) => {
      clientTotalsList.push(clientTotals[clientId][streamName]);
    });
  });
  // }, [ filteredBreakdown ]);

  const applyFilters = () => {
    setFilteredBreakdown(subscriptionBreakdown.filter((s) => {
      return (
        (!filters.clientName || s.clientName.toLowerCase().includes(filters.clientName.toLowerCase()))
        && (!filters.itemName || s.itemName.toLowerCase().includes(filters.itemName.toLowerCase()))
        && (!filters.invoiceNumber || s.invoiceNumbers.includes(filters.invoiceNumber))
        && (!currencyCode || s.currencyCode === currencyCode)
      );
    }));
  };

  useEffect(() => {
    const newTotals = filteredBreakdown.reduce((acc, bi) => {
      const discountedItemPrice = (bi.subTotal - (bi.subTotal * bi.discountPercentage));
      return ({
        partnerRevenue: (acc.partnerRevenue ?? 0) + bi.partnerRevenue,
        lwsRevenue: acc.lwsRevenue + bi.lwsRevenue,
        subTotal: acc.subTotal + bi.subTotal,
        discountedTotal: acc.discountedTotal + discountedItemPrice,
        proratedTotal: acc.proratedTotal + (discountedItemPrice * bi.prorationPercentage),
        taxes: acc.taxes + bi.taxData.reduce((totalTax, td) => totalTax + td.taxAmount, 0),
        total: acc.total + bi.total,
      });
    }, {
      partnerRevenue: 0,
      lwsRevenue: 0,
      subTotal: 0,
      discountedTotal: 0,
      proratedTotal: 0,
      taxes: 0,
      total: 0,
    });
    setTotals(newTotals);
  }, [ filteredBreakdown ]);

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  return (<>
    <Form onSubmit={(e) => {
      e.preventDefault();
      applyFilters();
    }}>
      {/* <Form.Row >
        <Form.Label column xs="2">Revenue Stream Filter</Form.Label>
        <Col xs="auto">
          <Form.Control
            id="revenueStreamFilter"
            name="revenueStream"
            value={filters.revenueStream}
            placeholder="Revenue Stream"
            onChange={handleFilterChange}
          />
        </Col>
      </Form.Row> */}
      <Form.Row >
        <Form.Label column xs="2">Item Name Filter</Form.Label>
        <Col xs="auto">
          <Form.Control
            id="itemNameFilter"
            name="itemName"
            value={filters.itemName}
            placeholder="Item Name"
            onChange={handleFilterChange}
          />
        </Col>
      </Form.Row>
      <Form.Row >
        <Form.Label column xs="2">Client Name Filter</Form.Label>
        <Col xs="auto">
          <Form.Control
            id="clientNameFilter"
            name="clientName"
            value={filters.clientName}
            placeholder="Client Name"
            onChange={handleFilterChange}
          />
        </Col>
      </Form.Row>
      <Form.Row >
        <Form.Label column xs="2">Invoice Number Filter</Form.Label>
        <Col xs="auto">
          <Form.Control
            id="invoiceNumberFilter"
            name="invoiceNumber"
            value={filters.invoiceNumber}
            placeholder="Invoice Number(s)"
            onChange={handleFilterChange}
          />
        </Col>
      </Form.Row>
      <Form.Row >
        <Form.Label column xs="2" />
        <Col xs="auto">
          <Button variant="primary" type="submit" style={{ marginTop: 10 }}>
            Filter
            {/* disabled={loadedState === 'loading'}> */}
            {/* {loadedState === 'loading' ? (<>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              /> Loading...</>)
              : (monthlySummary ? 'Reload Summary' : 'Load Summary')
            } */}
          </Button>
        </Col>
        <Col></Col>
      </Form.Row>
    </Form>
    <DownloadCSV filename="client_breakdown">
      <Table striped bordered hover variant="dark" size="sm">
        <thead>
          <tr>
            {/* <th>GSID</th> */}
            {/* <th>Revenue Stream</th> */}
            <th>Client / Item</th>
            <th>Partner Share</th>
            <th>LWS Share</th>
            <th>Subtotal</th>
            <th>Discounted</th>
            <th>Prorated</th>
            <th>Taxes</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {clientTotalsList?.map((ct, i) => (
            <tr key={i}>
              {/* <td>
                <Link
                  to={`/charges/${ct.generatedSubscriptionId}`}
                  target="_blank"
                >
                  {ct.generatedSubscriptionId}
                </Link>
              </td> */}
              {/* <td>{ps.revenueStream}</td> */}
              <td>{ct.clientName}<br /><em>{ct.revenueStream}</em></td>
              <td>{roundFloatToCentsAsDollar(ct.partnerRevenue)}</td>
              <td>{roundFloatToCentsAsDollar(ct.lwsRevenue)}</td>
              <td>{roundFloatToCentsAsDollar(ct.subTotal)}</td>
              <td>{roundFloatToCentsAsDollar(ct.discountedTotal, 'up')}</td>
              <td>{roundFloatToCentsAsDollar(ct.finalProratedTotal, 'up')}</td>
              <td>{roundFloatToCentsAsDollar(ct.taxes, 'up')}</td>
              <td>{roundFloatToCentsAsDollar(ct.finalProratedTotal + ct.taxes, 'up')} / {roundFloatToCentsAsDollar(ct.total, 'up')}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            {/* <th>Revenue Stream</th> */}
            {/* <th></th> */}
            <th></th>
            <th>{roundFloatToCentsAsDollar(totals.partnerRevenue)}</th>
            <th>{roundFloatToCentsAsDollar(totals.lwsRevenue)}</th>
            <th>{roundFloatToCentsAsDollar(totals.subTotal)}</th>
            <th>{roundFloatToCentsAsDollar(totals.discountedTotal)}</th>
            <th>{roundFloatToCentsAsDollar(totals.proratedTotal)}</th>
            <th>{roundFloatToCentsAsDollar(totals.taxes)}</th>
            <th>{roundFloatToCentsAsDollar(totals.total)}</th>
          </tr>
        </tfoot>
      </Table>
    </DownloadCSV>
  </>);
};
