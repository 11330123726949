import { Table } from "react-bootstrap";

import { HashOf, keys } from "@jamesgmarks/utilities";

import { DownloadCSV } from "../../../../parts/DownloadCSV";
import { EHideableColumns } from "./MonthlyBillingReport";
import { ICreditTotals } from "../../../../../../../rentsync-api/reporting";
import { IMonthlyPerStreamSummaryItem } from "../../../../../redux/features/reporting/IMonthlySummaryData";
import { partnerCoinStyle } from './styles';
import { roundFloatToCentsAsDollar } from "@hydra/internal";

interface MonthlyRevenueSummaryProps {
  creditTotals: HashOf<HashOf<ICreditTotals>>;
  currencyCode: string;
  hiddenColumns?: EHideableColumns[];
  perStreamSummary: HashOf<HashOf<IMonthlyPerStreamSummaryItem>>;
}

export const MonthlyRevenueSummary = ({
  creditTotals,
  currencyCode,
  hiddenColumns = [],
  perStreamSummary,
}: MonthlyRevenueSummaryProps) => {
  const showAccountNumber = !hiddenColumns.includes(EHideableColumns.accountNumber);
  const showDiscounts = !hiddenColumns.includes(EHideableColumns.discountAmount);

  const depositTotal = perStreamSummary[currencyCode]?.['Deposit'] ?? {
    itemName: 'Deposit',
    subTotal: 0,
    tax: 0,
    total: 0,
    partnerShare: 0,
    lwsShare: 0,
  };

  const revenueTotal = {
    itemName: 'Revenue Total',
    subTotal: 0,
    tax: 0,
    total: 0,
    partnerShare: 0,
    lwsShare: 0,
  } as IMonthlyPerStreamSummaryItem;

  const totalBillings = {
    itemName: 'Total Billings',
    subTotal: 0,
    tax: 0,
    total: 0,
    partnerShare: 0,
    lwsShare: 0,
  } as IMonthlyPerStreamSummaryItem;

  const addItemToRevenueTotal = (totalReference: IMonthlyPerStreamSummaryItem, item: IMonthlyPerStreamSummaryItem) => {
    totalReference.subTotal = totalReference.subTotal + item.subTotal;
    totalReference.tax = totalReference.tax + item.tax;
    totalReference.total = totalReference.total + item.total;
    totalReference.partnerShare = totalReference.partnerShare + item.partnerShare;
    totalReference.lwsShare = totalReference.lwsShare + item.lwsShare;
  };

  const removeCreditFromRevenueTotal = (
    totalReference: IMonthlyPerStreamSummaryItem,
    credit: IMonthlyPerStreamSummaryItem,
  ) => {
    totalReference.subTotal = totalReference.subTotal - Math.abs(credit.subTotal);
    totalReference.tax = totalReference.tax - Math.abs(credit.tax);
    totalReference.total = totalReference.total - Math.abs(credit.total);
    totalReference.partnerShare = totalReference.partnerShare - Math.abs(credit.partnerShare);
    totalReference.lwsShare = totalReference.lwsShare - Math.abs(credit.lwsShare);
  };

  addItemToRevenueTotal(totalBillings, { ...depositTotal, lwsShare: 0 } as IMonthlyPerStreamSummaryItem);

  return (
    <>
      <DownloadCSV filename="rev_summary">
        <Table striped bordered hover variant="dark" size="sm">
          <thead>
            <tr>
              <th>Item</th>
              { showAccountNumber && <th>Account #</th> }
              <th>Subtotal</th>
              <th>Tax</th>
              <th>Total</th>
              <th>Partner Share</th>
              <th>LWS Share</th>
              { showDiscounts && <th>Discounts</th> }
            </tr>
          </thead>
          <tbody>
            {keys(perStreamSummary[currencyCode] ?? {})
              .filter(itemLabel => (`${itemLabel}` ?? '').toLowerCase() !== 'deposit')
              .sort()
              .map((itemLabel, i) => {
                const itemTotal = perStreamSummary[currencyCode][itemLabel];
                addItemToRevenueTotal(revenueTotal, itemTotal);
                return (
                  <tr key={i}>
                    <td>
                      {itemTotal.itemName.slice(itemTotal.itemName.startsWith('*') ? 1 : 0)}
                      {itemTotal.itemName.startsWith('*') ? <span style={partnerCoinStyle}>partner invoiced</span> : ''}
                    </td>
                    { showAccountNumber && <td /> }
                    <td>{roundFloatToCentsAsDollar(itemTotal.subTotal)}</td>
                    <td>{roundFloatToCentsAsDollar(itemTotal.tax)}</td>
                    <td>{roundFloatToCentsAsDollar(itemTotal.total)}</td>
                    <td>{roundFloatToCentsAsDollar(itemTotal.partnerShare)}</td>
                    <td>{roundFloatToCentsAsDollar(itemTotal.lwsShare)}</td>
                    { showDiscounts && <td>{roundFloatToCentsAsDollar(itemTotal.discounts)}</td> }
                  </tr>
                );
              })}
            {/* Credits */}
            {keys(creditTotals![currencyCode] ?? {}).sort().map((creditLabel, i) => {
              const creditTotal = creditTotals[currencyCode][creditLabel];
              const lwsShare = creditTotal.amount - creditTotal.partner_clawback;
              removeCreditFromRevenueTotal(revenueTotal, {
                itemName: 'Credit',
                subTotal: (creditTotal.amount),
                tax: (creditTotal.tax),
                total: (creditTotal.total),
                partnerShare: (creditTotal.partner_clawback),
                lwsShare: (lwsShare),
              } as IMonthlyPerStreamSummaryItem);
              return (
                <tr key={i}>
                  <td>{creditLabel}</td>
                  { showAccountNumber && <td /> }
                  <td>({roundFloatToCentsAsDollar(creditTotal.amount)})</td>
                  <td>({roundFloatToCentsAsDollar(creditTotal.tax)})</td>
                  <td>({roundFloatToCentsAsDollar(creditTotal.total)})</td>
                  <td>({roundFloatToCentsAsDollar(creditTotal.partner_clawback)})</td>
                  <td>({roundFloatToCentsAsDollar(creditTotal.amount - creditTotal.partner_clawback)})</td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr key='revenue_total'>
              <td>
                {revenueTotal.itemName.slice(revenueTotal.itemName.startsWith('*') ? 1 : 0)}
                {revenueTotal.itemName.startsWith('*') ? <span style={partnerCoinStyle}>partner invoiced</span> : ''}
              </td>
              { showAccountNumber && <td /> }
              <td>{roundFloatToCentsAsDollar(revenueTotal.subTotal)}</td>
              <td>{roundFloatToCentsAsDollar(revenueTotal.tax)}</td>
              <td>{roundFloatToCentsAsDollar(revenueTotal.total)}</td>
              <td>{roundFloatToCentsAsDollar(revenueTotal.partnerShare)}</td>
              <td>{roundFloatToCentsAsDollar(revenueTotal.lwsShare)}</td>
            </tr>

            {/* Deposits */}
            <tr key='deposit'>
              <td>
                {depositTotal.itemName.slice(depositTotal.itemName.startsWith('*') ? 1 : 0)}
                {depositTotal.itemName.startsWith('*') ? <span style={partnerCoinStyle}>partner invoiced</span> : ''}
              </td>
              { showAccountNumber && <td /> }
              <td>{roundFloatToCentsAsDollar(depositTotal.subTotal)}</td>
              <td>{roundFloatToCentsAsDollar(depositTotal.tax)}</td>
              <td>{roundFloatToCentsAsDollar(depositTotal.total)}</td>
              <td>{roundFloatToCentsAsDollar(depositTotal.partnerShare)}</td>
              <td>{roundFloatToCentsAsDollar(0)}</td>
            </tr>
            {addItemToRevenueTotal(totalBillings, revenueTotal)}
            <tr key='total'>
              <td>
                {totalBillings.itemName.slice(totalBillings.itemName.startsWith('*') ? 1 : 0)}
                {totalBillings.itemName.startsWith('*') ? <span style={partnerCoinStyle}>partner invoiced</span> : ''}
              </td>
              { showAccountNumber && <td /> }
              <td>{roundFloatToCentsAsDollar(totalBillings.subTotal)}</td>
              <td>{roundFloatToCentsAsDollar(totalBillings.tax)}</td>
              <td>{roundFloatToCentsAsDollar(totalBillings.total)}</td>
              <td>{roundFloatToCentsAsDollar(totalBillings.partnerShare)}</td>
              <td>{roundFloatToCentsAsDollar(totalBillings.lwsShare)}</td>
            </tr>
          </tfoot>
        </Table>
      </DownloadCSV>
    </>
  );
};
