import { Table } from 'react-bootstrap';
import { IRevenueBreakdownTable } from '../../../../../redux/features/reporting/IMonthlySummaryData';
import {DownloadCSV} from "../../../../parts/DownloadCSV";
import { roundFloatToCentsAsDollar } from '@hydra/internal';

export const MonthlyOverview = ({
  revenueSummary,
}: {
  revenueSummary: IRevenueBreakdownTable[]
}) => {

  const revenueSummaryWithTotals = (revenueSummary ?? []).map(rs => {
    const totals = rs.data.reduce((acc, d) => {
      return {
        revenueType: 'Total',
        subtotal: acc.subtotal + d.subtotal,
        tax: acc.tax + d.tax,
        total: acc.total + d.total,
        lwsShare: acc.lwsShare + d.lwsShare,
      };
    }, {
      revenueType: 'Total',
      subtotal: 0,
      tax: 0,
      total: 0,
      lwsShare: 0,
    } as { revenueType: string, subtotal: number, tax: number, total: number, lwsShare: number });
    return {
      ...rs,
      totals,
    };
  }) as (
    IRevenueBreakdownTable
    & { totals: { revenueType: string, subtotal: number, tax: number, total: number, lwsShare: number } }
  )[];

  return (<>
    <h4 style={{ marginTop: 30, marginBottom: 30 }}>Currency Totals Breakdown</h4>
    {revenueSummaryWithTotals?.map(rs => (
      <div key={rs.currencyCode} style={{ marginTop: 40 }}>
        <u><h4>{rs.currencyCode} Overview</h4></u>
        <DownloadCSV filename={rs.currencyCode + ' Overview'}>
          <Table striped bordered hover variant="dark" size="sm">
            <thead>
              <tr>
                <th>Revenue Type</th>
                <th>Subtotal</th>
                <th>Tax</th>
                <th>Total</th>
                <th>Lws Share</th>
              </tr>
            </thead>
            <tbody>
              {
                (rs?.data ?? [])
                  .slice()
                  .sort((a, b) => (a.revenueType ?? '').toLowerCase().includes('credit') ? 1 : -1)
                  .map((d, i) => (
                    <tr key={i}>
                      <td>
                        { (d.revenueType[0] ?? '').toUpperCase() + (d.revenueType ?? '').slice(1) }
                      </td>
                      <td>{roundFloatToCentsAsDollar(d.subtotal)}</td>
                      <td>{roundFloatToCentsAsDollar(d.tax)}</td>
                      <td>{roundFloatToCentsAsDollar(d.total)}</td>
                      <td>{roundFloatToCentsAsDollar(d.lwsShare)}</td>
                    </tr>
                  ))}
            </tbody>

            <tfoot>
              <tr>
                <td>{rs.totals.revenueType}</td>
                <td>{roundFloatToCentsAsDollar(rs.totals.subtotal)}</td>
                <td>{roundFloatToCentsAsDollar(rs.totals.tax)}</td>
                <td>{roundFloatToCentsAsDollar(rs.totals.total)}</td>
                <td>{roundFloatToCentsAsDollar(rs.totals.lwsShare)}</td>
              </tr>
            </tfoot>
          </Table>
        </DownloadCSV>

      </div>
    ))}
  </>);
};
