import { Box, capitalize, Card, Typography } from "@mui/material";

import { floatVal, strVal } from "@jamesgmarks/utilities";

import {
  dateFromYmd,
  makeYmd,
  roundedInvoiceLineTotal,
  toDollarAmount,
} from "../../../app-utils";
import { getInvoiceState } from "../../../app-utils/invoices";
import { IRecentInvoice } from "../../../redux/features/clients/clientsSlice";
import { roundFloatToCents } from "@hydra/internal";

interface IRecentInvoiceProps {
  invoice: IRecentInvoice;
}

export const RecentInvoice = ({
  invoice,
}: IRecentInvoiceProps) => {
  const invoiceSubtotal = roundFloatToCents(
    (invoice.lines ?? []).reduce((acc, line) => acc + (roundedInvoiceLineTotal(line)), 0),
  );

  const totalTaxAmount = roundFloatToCents(invoiceSubtotal * +(invoice?.taxRate || '0'));
  const invoiceTotal = invoiceSubtotal + totalTaxAmount;

  const outstandingAmount = (
    invoice?.freshbooksOutstandingAmount
      ? floatVal(invoice?.freshbooksOutstandingAmount)
      : invoiceTotal ?? 0
  );
  const paidAmount = invoiceTotal - floatVal(outstandingAmount);

  return (
    <Card elevation={5} sx={{ mb: 1.5 }}>
      <Box m={0.5} p={1.2} sx={{ borderRadius: 3, width: '100%' }}>
        {
          <Typography variant='h6'>
            <b>{`Invoice${(invoice.invoiceNumber ?? '') && ` ${invoice.invoiceNumber}`}`}</b>
          </Typography>
        }
        <Typography><b>Date</b>: {`${makeYmd(dateFromYmd(strVal(invoice.invoiceDate)), true)}`}</Typography>
        {
          invoice.ownershipGroup
        && (
          <Typography>
            <b>Ownership Group</b>: {`${invoice.ownershipGroup.name} (${invoice.ownershipGroupId})`}
          </Typography>
        )
        }
        <Typography><b>State</b>: { capitalize(`${getInvoiceState(invoice)}`) }</Typography>
        <Typography><b>Amount Due</b>: {`${toDollarAmount(floatVal(invoice.amountInvoiced))}`}</Typography>
        <Typography><b>Amount Paid</b>: {toDollarAmount(paidAmount)}</Typography>
        <Typography><b>Amount Credited</b>: {toDollarAmount(invoice.amountCredited)}</Typography>
        <Typography sx={{ fontSize: '0.8rem', color: 'gray', mt: 1 }}><b>ID</b>: {invoice.id}</Typography>
      </Box>
    </Card>
  );
};
