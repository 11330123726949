/* eslint-disable jsx-a11y/heading-has-content */
import { useCallback, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Card,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TextField,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';

import {
  floatVal,
  intVal,
  Nullable,
  replaceAt,
} from '@jamesgmarks/utilities';

import {
  StyledTableCell,
  StyledTableRow,
} from '../../parts/mui/StyledTables';
import CancelIcon from '@mui/icons-material/Cancel';
import pdfFileIcon from '../../../assets/images/pdf.png';

import { ClientDropdown } from '../../parts/ClientDropdown';
import { Charge } from '../../../../../entities/hydra';
import CreditLine from './CreditLine';
import { CreditModal } from './CreditModal';
import { EHydraCreditNoteState } from '../../../entity-interfaces/EHydraCreditNoteState';
import { IClientDropdownItem } from '../../../entity-interfaces/IClientDropdownItem';
import { ICredit } from '../../../interfaces/ICredit';
import { ICreditNote } from '../../../interfaces/ICreditNote';
import { IOwnershipGroupDropdownItem } from '../../../entity-interfaces/IOwnershipGroupDropdownItem';
import { OwnershipGroupDropdown } from '../../parts/OwnershipGroupDropdown';
import { loadSubscriptionsLookup } from '../../../redux/features/lookups/actions';
import { toDollarAmount } from '../../../app-utils';
import { useAppSelector } from '../../../redux/hooks';
import { strVal } from '@jamesgmarks/utilities';
import { distinctOn } from '@jamesgmarks/utilities';

import '../../../assets/css/skeleton-loading.css';
import { capitalize, getPartsFromIsoString } from '../../../app-utils/helpers';
import { ConfirmationModal } from '../../parts/ConfirmationModal';
import { CreditNoteNotesSection } from './CreditNoteNotesSection';
import { CreditNoteVoidSection } from './CreditNoteVoidSection';
import {
  applyCreditBalanceToSourceInvoices,
  triggerCreditNotePdfGeneration,
} from '../../../redux/features/credit-notes/actions';
import { REACT_APP_API_ROOT_URI } from '../../../App';
import { EUploadStatus } from '../../../entity-interfaces/IPdfGeneration';
import { displayFile } from '../../../app-utils/display-file';
import { getCreditNoteDate } from './CreditView';
import { Link, useHistory } from 'react-router-dom';
import { ISubscriptions } from '@llws/lift-entity-interfaces';
import { roundFloatToCentsAsDollar } from '@hydra/internal';

const createEmptyCreditNoteObject = () => (
  {
    billingAddress: null,
    credits: [] as ICredit[],
    currencyCode: 'CAD',
    freshbooksCreditNoteId: null,
    noteDate: getCreditNoteDate(),
    notes: null,
    ownershipGroupId: null,
    pdfGenerations: [],
    state: EHydraCreditNoteState.unpublished,
    subtotalAmount: '0',
    taxAmount: '0.00',
    taxName: 'No Tax',
    taxRate: '0',
    voidState: null,
    voidRequestReason: null,
    voidApproveOrDenyReason: null,
  } as ICreditNote
);

const convertIsoStringToDate = (isoString: string) => {
  const [ year, month, dateOfMonth ] = isoString.slice(0, 10).split('-');
  return new Date(intVal(year), intVal(month) - 1, intVal(dateOfMonth));
};

interface ICreditNoteSingleViewProps {
  creditNote?: Nullable<ICreditNote>,
  onSave: (note: ICreditNote) => void,
}

export const CreditNoteSingleView = ({
  creditNote: incomingCreditNote,
  onSave,
}: ICreditNoteSingleViewProps) => {
  const history = useHistory();

  const clientSubscriptions = useAppSelector((state) => state.lookups.subscriptions);
  const currentCreditNote = useAppSelector((state) => state.creditNotes.currentCreditNote);
  const [ creditNote, setCreditNote ] = useState(incomingCreditNote ?? createEmptyCreditNoteObject());
  const [ noteDate, setNoteDate ] = useState(convertIsoStringToDate(creditNote.noteDate));
  const [ searchClientId, setSearchClientId ] = useState<Nullable<number>>(creditNote?.clientId ?? null);
  const [ searchClient, setSearchClient ] = useState<Nullable<IClientDropdownItem>>(null);
  const [ searchOwnershipGroupId, setSearchOwnershipGroupId ] = (
    useState<Nullable<number>>(creditNote?.ownershipGroupId ?? null)
  );
  const [ searchOwnershipGroup, setSearchOwnershipGroup ] = useState<Nullable<IOwnershipGroupDropdownItem>>(null);
  const [ allowEdits, setAllowEdits ] = useState(creditNote?.id ? false : true);

  const [ showSubscriptionSelectModal, setShowSubscriptionSelectModal ] = useState(false);

  const [ clawbackFromPartner ] = useState(true);

  const [ showConfirmationModal, setShowConfirmationModal ] = useState(false);
  const [ confirmationModalOnConfirm, setConfirmationModalOnConfirm ] = useState<() => void>(() => {});
  const [ confirmationModalPromptText, setConfirmationModalPromptText ] = useState('');

  const [ showApplyAllModal, setShowApplyAllModal ] = useState(false);

  const [ creditLineAmountOverride, setCreditLineAmountOverride ] = useState<Nullable<number>>(null);
  const [ creditLineDescriptionOverride, setCreditLineDescriptionOverride ] = useState<Nullable<string>>(null);

  useEffect(() => {
    setCreditNote((oldCreditNote) => updatedCreditNoteInfo({
      ...oldCreditNote,
      credits: (
        (oldCreditNote.credits ?? []).map((credit) => ({
          ...credit,
          clawbackFromPartner,
        }))
      ),
    }));
  }, [ clawbackFromPartner ]);

  useEffect(() => {
    !allowEdits
      ? setSearchClientId(creditNote.clientId ?? null)
      : (
        setCreditNote((oldCreditNote) => ({
          ...oldCreditNote,
          billingAddress: searchClient?.billingAddress ?? {},
          billingAccountId: searchClient?.billingAccountId,
        }))
      );
  }, [ creditNote.clientId, allowEdits, searchClient ]);

  useEffect(() => {
    !allowEdits
      ? setSearchOwnershipGroupId(creditNote.ownershipGroupId ?? null)
      : (
        setCreditNote((oldCreditNote) => ({
          ...oldCreditNote,
          billingAddress: searchOwnershipGroup?.billingAddress ?? {},
          ownershipGroupId: searchOwnershipGroup?.id,
        }))
      );
  }, [ creditNote.ownershipGroupId, allowEdits, searchOwnershipGroup ]);

  useEffect(() => {
    if (incomingCreditNote) {
      setCreditNote(incomingCreditNote);
    }
  }, [ incomingCreditNote ]);

  // TODO: AMIR AMIR AMIR AMIR AMIR AMIR AMIR
  // When credit lines change, update creditNote via setCreditNote
  // Same with client / ownership group, etc.

  const updatedCreditNoteInfo = (newCreditNote: ICreditNote): ICreditNote => {
    const firstCredit = newCreditNote.credits[0] ?? null;
    const chargeHasTaxesList = firstCredit?.generatedSubscription?.chargeHasTaxes;
    const firstGenSubHasTaxes = (
      chargeHasTaxesList && (chargeHasTaxesList ?? []).length > 0
        ? chargeHasTaxesList[0]
        : null
    );

    const taxRate = floatVal(firstGenSubHasTaxes?.taxRate ?? '0');
    const taxName = firstGenSubHasTaxes?.taxName ?? 'No Tax';
    const creditNoteSubtotalAmount = (
      (newCreditNote.credits ?? []).reduce((acc, credit) => acc + floatVal(credit.amount), 0)
    );
    const creditNoteTaxAmount = creditNoteSubtotalAmount * taxRate;

    const returnCreditNote: ICreditNote = {
      ...newCreditNote,
      taxName: taxName ?? '',
      taxRate: `${taxRate}`,
      currencyCode: firstCredit?.currencyCode ?? '',
      noteDate: newCreditNote.noteDate ?? getCreditNoteDate(),
      taxAmount: roundFloatToCentsAsDollar(creditNoteTaxAmount).replace('$', ''),
      subtotalAmount: `${creditNoteSubtotalAmount}`,
    };

    return returnCreditNote;
  };

  const creditLineChanged = (credit: ICredit, index: number) => {
    setCreditNote((oldCreditNote) => updatedCreditNoteInfo({
      ...oldCreditNote,
      credits: replaceAt(oldCreditNote.credits ?? [], index, credit),
    }));
  };

  const removeCreditLine = (index: number) => {
    setCreditNote((oldCreditNote) => {
      const updatedCredits = (oldCreditNote.credits ?? []).filter((_, i) => i !== index);

      const updated = updatedCreditNoteInfo(
        {
          ...oldCreditNote,
          credits: updatedCredits,
        },
      );

      return updated;
    });
  };

  const addCreditLine = useCallback((newCredit: ICredit) => {
    setCreditNote((oldCreditNote) => updatedCreditNoteInfo({
      ...oldCreditNote,
      credits: [
        ...(oldCreditNote.credits ?? []),
        newCredit,
      ],
    }));
  }, []);

  // const createGoodwillCredit = async () => {
  //   if (!creditNote.billingAccountId) {
  //     alert(
  //       'A billing account does not exist for this credit note. '
  //       + 'We have bigger problems than not adding a credit line.',
  //     );
  //     return;
  //   }

  //   addCreditLine({
  //     freshbooksClientId: creditNote.billingAccountId,
  //     creditNumber: null,
  //     clientId: null,
  //     partnerId: null,
  //     partnerClawback: null,
  //     clawbackFromPartner:false,
  //     invoiceId: null,
  //     subscriptionId: null,
  //     generatedSubscriptionId: null,
  //     currencyCode: 'CAD', // TODO: Determine currency by credit note parameters? (ie. client, og)
  //     amount: '0.00',
  //     taxRate: '0.00',
  //     taxAmount: '0.00',
  //     description: 'Goodwill Credit',
  //     hydraState: 'active',
  //     creditDate: creditNote.noteDate,
  //     // creditMethod: 'stand-alone',
  //   });
  // };

  const createSubscriptionCredit = useCallback(
    () => {
      allowEdits && setShowSubscriptionSelectModal(true);
      if (searchClient?.id) {
        loadSubscriptionsLookup({ clientId: searchClient.id });
      }
    },
    [ searchClient, allowEdits ],
  );

  useEffect(() => {
    searchClient && createSubscriptionCredit();
  }, [ searchClient, createSubscriptionCredit ]);

  useEffect(() => {
    if (currentCreditNote) {
      setNoteDate(convertIsoStringToDate(currentCreditNote.noteDate));
      history.push(`/credit_notes/${currentCreditNote.id}`);
    }
  }, [ currentCreditNote, history, setNoteDate ]);

  const addCreditsForCharge = useCallback(
    (charge: Charge, subscription: ISubscriptions, invoiceIds: string[]) => {
      if (!creditNote.billingAccountId) {
        alert(
          'A billing account does not exist for this credit note. '
        + 'We have bigger problems than not adding a credit line.',
        );
        return;
      }
      if (!subscription.id) {
        alert('No subscription ID');
        return;
      }
      if (!subscription.baseSubscription) {
        alert('No base subscription');
        return;
      }

      // TODO: Validate that the charge can be placed into a single credit... if spread over multiple tax rates or w/e, we may have a problem.s
      return distinctOn(charge.chargeHasTaxes, (a, b) => a.invoiceId === b.invoiceId)
        .map((cht) => {
          if (!invoiceIds.includes(strVal(cht.invoiceId))) {
            return null;
          }

          const creditAmount = cht.invoice.lines.reduce(
            (acc, cur) =>
              (cur.lineType !== 'charge' || cur.chargeId !== charge.id)
                ? acc
                : acc + floatVal(cur.lineTotal)
            , 0,
          );

          const creditLineToAdd = {
            freshbooksClientId: creditNote.billingAccountId,
            creditNumber: null,
            clientId: creditNote.clientId ?? null,
            partnerId: subscription.baseSubscription?.partnerId ?? null,
            clawbackFromPartner,
            invoiceId: intVal(cht.invoice.id) ?? null, // TODO: rename property name to `number`?
            invoiceNumber: intVal(cht.invoice.invoiceNumber) ?? null,
            invoiceDate: getPartsFromIsoString(`${cht.invoice.invoiceDate}`, 'date'),
            subscriptionId: subscription.id,
            generatedSubscription: charge,
            generatedSubscriptionId: charge.id,
            currencyCode: cht.currency ?? 'CAD',
            amount: creditAmount.toString(),
            taxRate: cht.taxRate ?? '0.00',
            taxAmount: roundFloatToCentsAsDollar(floatVal(cht.taxRate ?? '0.00') * creditAmount).replace('$', ''),
            description: `Credit for ${subscription.invoiceDescription}`, // TODO: Get invoice number - note manager would be awful for this right now.
            hydraState: 'active',
            creditDate: new Date(cht.invoice.invoiceDate),
          } as ICredit;

          addCreditLine(creditLineToAdd);

          return creditLineToAdd;
        });
    }, [
      addCreditLine,
      clawbackFromPartner,
      creditNote.billingAccountId,
      creditNote.clientId,
    ],
  );

  const subtotal = floatVal(creditNote?.subtotalAmount ?? 0);

  const taxes = floatVal(creditNote.taxAmount);

  const theme: Theme = useTheme();

  const canBeDeleted = (
    [ EHydraCreditNoteState.unpublished, EHydraCreditNoteState.draft ].includes(creditNote.state) && creditNote.id
  );

  const hasDownload = (
    (creditNote.pdfGenerations ?? []).filter((g) => g.uploadStatus === EUploadStatus.completed).length > 0
  );

  const viewGcpCreditNotePdf = async () => {
    if (!creditNote.noteNumber) {
      console.info('This credit note has not been created yet.');
      return;
    }

    const url = (
      `${REACT_APP_API_ROOT_URI}/downloads/download_credit_note_pdf/${creditNote.noteNumber}`
    );

    const filename = `CN_${creditNote.noteNumber}`;

    await displayFile(url, filename);
  };

  return (
    <>
      <Grid item mx='auto' px={15} mt={2} mb={5} xs={8}>
        <Link to='/credit_notes'>
          <Button variant='contained' color='info'>&larr; View All Credit Notes</Button>
        </Link>
      </Grid>

      <Grid sx={{ mx: 'auto', px: 15, paddingBottom: '2rem' }}>
        <Typography
          sx={{ color: '#18a558' }}
          variant='h5'
        >
          <u>{allowEdits ? 'CREATE MODE': 'VIEW MODE'}</u>
          {
            hasDownload
              && (
                <a
                  href='#'
                  style={{ marginLeft: 15 }}
                  onClick={() => viewGcpCreditNotePdf()}
                >
                  <img
                    src={pdfFileIcon}
                    alt="pdf"
                    height="20px"
                    style={{ filter: `grayscale(${hasDownload ? '0' : '1'})` }}
                    title='Download available'
                  />
                </a>
              )
          }

          <Button
            color='secondary'
            variant='contained'
            size='small'
            sx={{ fontSize: '0.9rem', marginLeft: 4 }}
            onClick={() => creditNote.id && triggerCreditNotePdfGeneration([ creditNote.id ])}
          >
            Generate PDF
          </Button>

        </Typography>

        <br />

        <Grid container spacing={4}>
          <Grid item xs={12} md={5}>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <StyledTableRow dark={true}>
                    <StyledTableCell dark={true} align='right'><b>Credit Note #</b>:</StyledTableCell>
                    <StyledTableCell dark={true} align='left'>
                      {`${creditNote.noteNumber ?? ''}`}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow dark={true}>
                    <StyledTableCell dark={true} align='right'><b>State</b>:</StyledTableCell>
                    <StyledTableCell dark={true} align='left'>
                      {capitalize(creditNote.state ?? '').replace('_', ' ')}
                      {creditNote.voidState && ` (${capitalize(creditNote.voidState.replace(/[_-]/g, ' '))})`}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow dark={true}>
                    <StyledTableCell dark={true} align='right'><b>Note Date</b>:</StyledTableCell>
                    <StyledTableCell dark={true} align='left'>
                      {
                        allowEdits
                          ?
                          <TextField
                            size='small'
                            type='date'
                            sx={{ input: { color: 'white' } }}
                            value={noteDate.toLocaleDateString()}
                            onChange={
                              ({ target: { value } }) => {
                                setNoteDate((old) => {
                                  const newNoteDate = (
                                    value
                                      ? convertIsoStringToDate(value)
                                      : new Date((new Date()).setHours(0, 0, 0, 0))
                                  );

                                  setCreditNote((old) => ({ ...old, noteDate: newNoteDate.toISOString() }));

                                  return newNoteDate;
                                });
                              }
                            }
                          />
                          :
                          creditNote.noteDate.slice(0, 10)
                      }
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <CreditModal
              show={showSubscriptionSelectModal}
              subscriptions={clientSubscriptions}
              onChargeSelected={
                useCallback((charge, subscription, invoiceIds) => {
                  addCreditsForCharge(charge, subscription, invoiceIds);
                  setShowSubscriptionSelectModal(false);
                }, [ addCreditsForCharge ])
              }
              onHide={() => setShowSubscriptionSelectModal(false)}
            />
          </Grid>

          <Grid item xs={12} md={7}>
            <Card style={{ padding: '.75rem' }}>
              {
                allowEdits
              && (
                <Button
                  color='warning'
                  onClick={() => {
                    setSearchClient(null);
                    setSearchClientId(null);
                    setSearchOwnershipGroup(null);
                    setSearchOwnershipGroupId(null);
                    setCreditNote(incomingCreditNote ?? createEmptyCreditNoteObject());
                  }}
                  variant='text'
                >
                  Clear
                </Button>
              )
              }
              {
                searchClient
                  ? (
                    <Box mt={1}>
                      <Typography sx={{ fontSize: '0.8rem', color: 'gray' }}><u>Client</u></Typography>
                      <Typography variant='h6'>{searchClient.name}</Typography>
                    </Box>
                  )
                  : (
                    <Box sx={{ mt: 0.5 }}>
                      <ClientDropdown
                        allowFreeform={false}
                        clientId={searchClientId}
                        onClientChanged={(client) => {
                          setSearchClientId(client ? intVal(client.id) : null);
                          setSearchClient(client);
                        }}
                        onClientFreeForm={(freeFormName: Nullable<string>) => {}}
                      />
                    </Box>
                  )
              }
              {
                searchOwnershipGroup
                  ? (
                    <>
                      <Typography sx={{ fontSize: '0.8rem', color: 'gray' }}><u>Ownership Group</u></Typography>
                      <Typography variant='h6'>{searchOwnershipGroup.name}</Typography>
                    </>
                  )
                  : (
                    allowEdits
                      ? (
                        <Box sx={{ mt: 1.5 }}>
                          <OwnershipGroupDropdown
                            disableIfNoOwnershipGroups
                            freshbooksClientId={searchClient?.freshbooksClientId}
                            allowFreeform={false}
                            ownershipGroupId={searchOwnershipGroupId}
                            onOwnershipGroupChanged={(ownershipGroup) => {
                              setSearchOwnershipGroupId(ownershipGroup ? intVal(ownershipGroup.id) : null);
                              setSearchOwnershipGroup(ownershipGroup ?? null);
                            }}
                            onOwnershipGroupFreeForm={(ownershipGroupString) => {
                              // TODO: Remove free-form support for ths one.
                            }}
                          />
                        </Box>
                      )
                      : null
                  )
              }
            </Card>

            {
              searchClient && creditNote?.billingAddress?.street1
                ? (
                  <Card sx={{ mt: 1, pl: 1.5, pt: 1.5, pb: 1 }}>
                    {
                      creditNote.billingAddress?.organization
                      && <Typography variant='subtitle1'>{creditNote.billingAddress?.organization}</Typography>
                    }
                    {
                      creditNote.billingAddress?.street1
                      && <Typography variant='subtitle1'>{creditNote.billingAddress?.street1}</Typography>
                    }
                    {
                      creditNote.billingAddress?.street2
                      && <Typography variant='subtitle1'>{creditNote.billingAddress?.street2}</Typography>
                    }
                    {
                      creditNote.billingAddress?.city
                      && <Typography component='span'>{`${creditNote.billingAddress?.city}`}</Typography>
                    }
                    {
                      creditNote.billingAddress?.province
                      && <Typography component='span'>{`, ${creditNote.billingAddress?.province}`}</Typography>
                    }
                    {
                      creditNote.billingAddress?.postalCode
                      && <Typography component='span'>{`, ${creditNote.billingAddress?.postalCode}`}</Typography>
                    }

                    {
                      creditNote.billingAddress?.country
                      && <Typography variant='subtitle1'>{creditNote.billingAddress?.country}</Typography>}
                  </Card>
                )
                : (
                  searchClient
                  && (
                    <Card sx={{ mt: 1, pl: 1.5, pt: 1.5, pb: 1 }}>
                      <Typography>Credit note has no billing address.</Typography>
                    </Card>
                  )
                )
            }
          </Grid>
        </Grid>

        <br />

        {
          !allowEdits
          && (
            <>
              <Box>
                <Button
                  variant='contained'
                  color='warning'
                  onClick={() => { setShowApplyAllModal(true); }}
                >
                  Apply All to Source Invoices
                </Button>
              </Box>

              <br />
            </>
          )
        }

        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Item</StyledTableCell>
                <StyledTableCell>Description</StyledTableCell>
                <StyledTableCell>Unit Cost</StyledTableCell>
                <StyledTableCell>&nbsp;</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {
                (creditNote.credits ?? []).length === 0
                && (
                  <StyledTableRow sx={{ backgroundColor: 'white' }}>
                    <StyledTableCell>
                      <div className="card">
                        <h4 className='loading' />
                        <h4 className='loading' />
                      </div>
                    </StyledTableCell>

                    <StyledTableCell>
                      <div className="card">
                        <h4 className='loading' />
                        <h4 className='loading' />
                        <h4 className='loading' />
                      </div>
                    </StyledTableCell>

                    <StyledTableCell>
                      <div className='card'>
                        <h4 className='loading' />
                      </div>
                    </StyledTableCell>

                    <StyledTableCell>
                      <CancelIcon sx={{ opacity: 0.3 }} />
                    </StyledTableCell>
                  </StyledTableRow>
                )
              }
              {
                creditNote.credits?.map((credit, index) => (
                  <CreditLine
                    key={index}
                    credit={credit}
                    allowEdits={allowEdits}
                    onChange={(changedCredit) => creditLineChanged(changedCredit, index)}
                    removeFn={() => {
                      creditNote.credits?.length === 1 && setShowSubscriptionSelectModal(true);
                      removeCreditLine(index);
                    }}
                    amountOverride={creditLineAmountOverride}
                    setAmountOverride={setCreditLineAmountOverride}
                    descriptionOverride={creditLineDescriptionOverride}
                    setDescriptionOverride={setCreditLineDescriptionOverride}
                  />
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
        <Paper>
          <Grid container my={2} sx={{ border: '1px solid #d3d3d3' }}>
            <Grid item container xs={12} px={2} pt={1} sx={{ backgroundColor: theme.palette.action.hover }}>
              <Grid item xs={8}>Subtotal</Grid>
              <Grid item xs={4} pr={1} textAlign='right'>{toDollarAmount(floatVal(creditNote.subtotalAmount))}</Grid>
            </Grid>

            <Grid item container xs={12} px={2} pb={1} sx={{ backgroundColor: theme.palette.action.hover }}>
              <Grid item xs={8}><i>{`${creditNote.taxName}`} ({`${floatVal(creditNote.taxRate) * 100}%`})</i></Grid>
              <Grid item xs={4} pr={1} textAlign='right'><i>{toDollarAmount(floatVal(creditNote.taxAmount))}</i></Grid>
            </Grid>

            <Grid
              container
              item
              mt={0}
              px={2}
              py={0.5}
              sx={{ color: 'white', backgroundColor: 'rgba(30,144,255,0.8)', border: '1px solid #7e7e7e' }}
              xs={12}
            >
              <Grid item xs={8}><b>TOTAL</b></Grid>
              <Grid item xs={4} pr={1} textAlign='right'><b>{toDollarAmount(subtotal + taxes)}</b></Grid>
            </Grid>
          </Grid>
        </Paper>

        <Grid container mt={2} spacing={1.5}>
          {
            allowEdits
            && (
              <Grid item xs={12} mb={2}>
                <Button
                  disabled={(creditNote.credits ?? []).length === 0}
                  onClick={
                    () => {
                      onSave(creditNote);
                      setAllowEdits(false);
                    }
                  }
                  variant='contained'
                >
                  Create
                </Button>
              </Grid>
            )
          }

          {
            canBeDeleted
            && (
              <Grid item container xs={12}>
                <Grid item>
                  <Button
                    color='error'
                    variant='outlined'
                    onClick={
                      () => {
                        setConfirmationModalOnConfirm(
                          () => () => setCreditNote((old) => {
                            const newCreditNote = { ...old, state: EHydraCreditNoteState.deleted };

                            onSave(newCreditNote);
                            return newCreditNote;
                          }),
                        );

                        setConfirmationModalPromptText('Are you sure you want to delete this Credit Note?');

                        setShowConfirmationModal(true);
                      }
                    }
                  >
                    Delete Credit Note
                  </Button>
                </Grid>
              </Grid>
            )
          }
          <Grid item container xs={12} spacing={1.5} alignItems='flex-start'>
            <Grid item container pl={1.5} xs={12} lg={6}>
              <CreditNoteNotesSection
                incomingCreditNote={incomingCreditNote}
                onSave={onSave}
                allowEdits={allowEdits}
                setCreditNote={setCreditNote}
              />
            </Grid>

            <Grid item container xs={12} lg={6}>
              <CreditNoteVoidSection
                creditNote={creditNote}
                onSave={onSave}
                setConfirmationModalPromptText={setConfirmationModalPromptText}
                setShowConfirmationModal={setShowConfirmationModal}
                setConfirmationModalOnConfirm={setConfirmationModalOnConfirm}
                setCreditNote={setCreditNote}
              />
            </Grid>
          </Grid>

        </Grid>

        <ConfirmationModal
          open={showConfirmationModal}
          setOpen={setShowConfirmationModal}
          promptText={confirmationModalPromptText}
          onConfirm={confirmationModalOnConfirm}
          onCancel={() => setShowConfirmationModal(false)}
          onClose={() => setShowConfirmationModal(false)}
        />
      </Grid>

      <ConfirmationModal
        open={showApplyAllModal}
        setOpen={setShowApplyAllModal}
        dialogTitle='Confirm Applying All Credits to Source Invoices'
        promptText="Are you sure that you want to create a payment for each credit of this credit note?"
        onConfirm={() => creditNote.id && applyCreditBalanceToSourceInvoices(creditNote.id)}
      />
    </>
  );
};
