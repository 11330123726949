import { useState } from "react";
import DatePicker from "react-datepicker";
import {
  Button, Col, Form, FormControl, Modal, Row, Table,
} from "react-bootstrap";
import { distinct, floatVal, Nullable } from "@jamesgmarks/utilities";
import { Commission, Invoice, InvoiceLine } from "../../../../../entities/hydra";
import { IAddCreditModalSubmitData } from "../../../interfaces/IAddCreditModalSubmitData";
import { useAppSelector } from "../../../redux/hooks";
import { loadMonthLockData } from "../../../redux/features/invoices/actions";

import { toDollarAmount } from '../../../app-utils';

export const AddCreditModal = ({
  generatedSubscriptionId,
  show,
  onHide,
  invoice,
  line,
  onSubmit,
}: {
  generatedSubscriptionId?: number,
  show: boolean,
  onHide?: (() => void),
  invoice: Nullable<Invoice>,
  line: Nullable<InvoiceLine>,
  onSubmit: (submitData: IAddCreditModalSubmitData) => void,
}) => {
  const hasPartner = (
    (line?.usageItems ?? [])
      .reduce((acc, ui) => [...acc, ...(ui.partnerIds ?? [])], [] as number[])
      .length > 0
  );
  const hasCommissions = ((/* TODO: line?.commissions ?? */ []).length > 0);
  const defaultDescription = `Credit for ${line?.description}`;
  const maxCreditAmount = (line?.quantity ?? 0) * (floatVal(line?.unitCost) ?? 0);
  const invoiceDate = new Date(invoice?.invoiceDate ?? '');

  const monthLocks = useAppSelector(state => state.invoices.monthLocks);

  const [creditDescription, setCreditDescription] = useState(defaultDescription);

  const [preFormatCreditAmount, setPreFormatCreditAmount] = useState(maxCreditAmount.toFixed(2));
  const [creditAmount, setCreditAmount] = useState(maxCreditAmount);

  const [creditDate, setCreditDate] = useState(new Date());
  const [clawbackFromPartner, setClawbackFromPartner] = useState(hasPartner);

  const invoiceYear = invoiceDate.getFullYear();
  const invoiceMonth = invoiceDate.getMonth() + 1;
  const monthLockForMonth = invoice ? monthLocks[`${invoiceYear}-${invoiceMonth}`] ?? null : null;
  if (invoice && invoiceYear && invoiceMonth && monthLockForMonth === null) {
    loadMonthLockData(invoiceYear, invoiceMonth);
  }

  const subscriptionIds = distinct(line?.usageItems.map(ui => ui.subscriptionId) ?? []);

  const linePercentageAmount = creditAmount / maxCreditAmount;

  return (
    <Modal
      centered
      dialogClassName="modal-60w"
      backdrop="static"
      animation={false}
      show={!!line && !!show}
      onClose={() => onHide?.()}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Add Credit
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm="3">
            Subscription ID(s):
          </Col>
          <Col sm="9">
            {subscriptionIds.join(', ')}
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }}><Col>
          <Form.Label>
            Credit Description:
          </Form.Label>
        </Col></Row>
        <Row style={{ marginTop: 5 }}>
          <Col sm="12">
            <FormControl
              as="textarea"
              value={creditDescription}
              onChange={(e) => setCreditDescription(e.target.value)}
            />
          </Col>
        </Row>
        <Form.Group as={Row} style={{ marginTop: 5 }}>
          <Form.Label column sm="3">
            Credit Amount ($):
          </Form.Label>
          <Col sm="9">
            <FormControl
              as="input"
              type="number"
              value={preFormatCreditAmount}
              onChange={(e) => setPreFormatCreditAmount(e.target.value)}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                const amount = !preFormatCreditAmount || Number.isNaN(floatVal(e.target.value))
                  ? 0
                  : Math.max(0, Math.min(floatVal(e.target.value), maxCreditAmount));

                setPreFormatCreditAmount(amount.toFixed(2));
                setCreditAmount(floatVal(amount.toFixed(2)));
              }
              }
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Tax @ {floatVal(line?.taxRate ?? '0') * 100}%:
          </Form.Label>
          <Col sm="9">
            {(creditAmount * floatVal(line?.taxRate ?? '0')).toFixed(2)}
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Credit Date:
          </Form.Label>
          <Col sm="9">
            <DatePicker
              dateFormat='yyyy-MM-dd'
              selected={creditDate}
              onChange={(date) => date && !Array.isArray(date) ? setCreditDate(date) : null}
            />
          </Col>
        </Form.Group>
        {hasCommissions &&
          <Form.Group as={Row}>
            <Col sm="3">
              Clawbacks
            </Col>
            <Col sm="9">
              <Table size="sm">
                <thead>
                  <tr>
                    <th>Sales Rep</th>
                    <th>Rate</th>
                    <th>Total Commission</th>
                    <th>Clawback Amt.</th>
                  </tr>
                </thead>
                <tbody>
                  {(/* TODO: line?.commissions ?? */ [] as Commission[]).map(c => (
                    <tr key={c.salesRepId}>
                      <td>{c.salesRepId}</td>
                      {/* <td>{c.commissionRate}</td> */}
                      <td>TODO: Rate</td>
                      <td>{c.commission}</td>
                      <td>{toDollarAmount(floatVal(c.commission) * linePercentageAmount)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <pre>
              </pre>
            </Col>
          </Form.Group>
        }
        {
          hasPartner
          && <>
            {/* TODO: (ISSUE:#159)
          <Form.Group as={Row}>
            <Col sm="3">
              Partner Clawback Amount:
            </Col>
            <Col sm="9">

            </Col>
          </Form.Group> */}
            <Form.Group as={Row}>
              <Col sm="12">
                <Form.Check
                  id={`add-credit-modal-partner-clawback-check`}
                  checked={clawbackFromPartner}
                  onChange={(e) => setClawbackFromPartner(e.target.checked)}
                  label={`Clawback from partner`}
                />
              </Col>
            </Form.Group>
          </>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            onSubmit({
              invoiceId: invoice?.id ?? null,
              subscriptionId: subscriptionIds.length === 1 ? subscriptionIds[0] : null,
              generatedSubscriptionId: null,
              creditDescription: creditDescription,
              creditAmount: creditAmount,
              creditDate: creditDate,
              clawbackFromPartner: clawbackFromPartner,
              currencyCode: invoice?.currency ?? 'CAD',
              taxName: line?.taxName ?? null,
              taxRate: floatVal(line?.taxRate ?? '0'),
              taxAmount: creditAmount * floatVal(line?.taxRate ?? '0'),
              freshbooksClientId: invoice?.freshbooksClientId,
            });
            onHide?.();
          }}
        >Add</Button>
        <Button onClick={onHide} variant="secondary">Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
};
