import { floatVal } from "@jamesgmarks/utilities";
import { Card, Table } from "react-bootstrap";
import { IInvoice } from "../../../entity-interfaces/IInvoice";
import { roundFloatToCentsAsDollar } from "@hydra/internal";

interface IInvoiceSendCardBodyProps {
  invoices: IInvoice[];
  numCheckedContacts: number;
}

export const InvoiceSendCardBody = ({
  invoices,
  numCheckedContacts,
}: IInvoiceSendCardBodyProps) => (
  <>
    <Card.Text><strong>Details:</strong></Card.Text>
    <Table striped bordered>
      <thead>
        <tr>
          <th>Invoice Count</th>
          <th>Email Count</th>
          <th>Subtotal</th>
          <th>Taxes</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{invoices.length}</td>
          <td>{numCheckedContacts}</td>
          <td>{roundFloatToCentsAsDollar(invoices.reduce((subtotal, i) => (subtotal + floatVal(i.amountInvoiced)), 0))}</td>
          <td>{roundFloatToCentsAsDollar(invoices.reduce((subtotal, i) => (subtotal + floatVal(i.taxes ?? '0')), 0))}</td>
          <td>
            {
              roundFloatToCentsAsDollar(invoices.reduce((subtotal, i) => (
                subtotal + floatVal(i.amountInvoiced ?? '0') + floatVal(i.taxes ?? '0')
              ), 0))
            }
          </td>
        </tr>
      </tbody>
    </Table>
  </>
);
