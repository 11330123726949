import { floatVal } from "@jamesgmarks/utilities";
import { Card, Table } from "react-bootstrap";
import { IAugmentedCreditNote } from "../../../interfaces/ICreditNote";
import { roundFloatToCentsAsDollar } from "@hydra/internal";

interface ICreditNoteSendCardBodyProps {
  creditNotes: IAugmentedCreditNote[];
  numCheckedContacts: number;
}

export const CreditNoteSendCardBody = ({
  creditNotes,
  numCheckedContacts,
}: ICreditNoteSendCardBodyProps) => (
  <>
    <Card.Text><strong>Details:</strong></Card.Text>
    <Table striped bordered>
      <thead>
        <tr>
          <th>Credit Note Count</th>
          <th>Email Count</th>
          <th>Subtotal</th>
          <th>Taxes</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{creditNotes.length}</td>
          <td>{numCheckedContacts}</td>
          <td>{roundFloatToCentsAsDollar(creditNotes.reduce((subtotal, i) => (subtotal + floatVal(i.subtotalAmount)), 0))}</td>
          <td>
            {roundFloatToCentsAsDollar(creditNotes.reduce((subtotal, i) => (subtotal + floatVal(i.taxAmount ?? '0')), 0))}
          </td>
          <td>
            {
              roundFloatToCentsAsDollar(creditNotes.reduce((subtotal, i) => (
                subtotal + floatVal(i.subtotalAmount ?? '0') + floatVal(i.taxAmount ?? '0')
              ), 0))
            }
          </td>
        </tr>
      </tbody>
    </Table>
  </>
);
