import { floatVal } from "@jamesgmarks/utilities";
import { TTableData } from ".";

import { Invoice } from "../../../../../../entities/hydra";
import { dateFromYmd, makeYmd } from "../../../../app-utils";
import { roundFloatToCentsAsDollar } from "@hydra/internal";

export const invoiceListFieldGetters: TTableData<Invoice>[] = [
  { name: 'Invoice Number', getter: (i) => i.invoiceNumber ?? `PV:${i.id}` },
  {
    name: 'Client', getter: (i) => (
      <>
        {
          (i.billingAccount?.clients ?? []).length === 1
            && (i.billingAccount?.clients ?? []).map(c => c.clientName).join(', ')
        }
        {
          (i.billingAccount?.clients ?? []).length > 1
            && (
              <>
                {(i.billingAccount?.clients ?? []).filter(c => c.isParentClient).map(c => c.clientName).join(', ')}
                {` (${(i.billingAccount?.clients ?? []).length} total clients)`}
              </>
            )
        }
        {
          (i.billingAccount?.partners ?? []).length === 1
                && (i.billingAccount?.partners ?? []).map(p => `${p.partnerName}`).join(', ')
        }
        {(i.billingAccount?.partners ?? []).length > 1 && 'Multiple Partners'}
      </>
    ),
  },
  { name: 'Date', getter: (i) => makeYmd(dateFromYmd((i.invoiceDate as unknown as string).split('T')[0]), true) },
  {
    name: 'Subtotal', getter: (i) => {
      const creditedAmount = floatVal((i.creditsApplied ?? []).reduce((acc, c) => acc + floatVal(c.amount), 0));
      return (
        <>
          <span style={{ whiteSpace: 'nowrap' }}>{roundFloatToCentsAsDollar(floatVal(i.amountInvoiced))} {i.currency}</span>
          {
            creditedAmount > 0
            && (
              <>
                <br />
                <small style={{ whiteSpace: 'nowrap' }}>Credit: {roundFloatToCentsAsDollar(creditedAmount)}</small>
                <br />
                <small style={{ whiteSpace: 'nowrap' }}>
                  Total: {roundFloatToCentsAsDollar(floatVal(i.amountInvoiced) - creditedAmount)}
                </small>
              </>
            )
          }
        </>
      );
    },
  },
  {
    name: 'State', getter: (i) => (
      i.thirdPartyInvoiceId
        ? `${i.freshbooksState}`
        : `${i.hydraState.replace('sent', 'published')}`
    ),
  },
  { name: 'Type', getter: (i) => i.generationType === 'manual' ? 'OTF' : 'Monthly' },
];
