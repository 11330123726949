import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { Button, Grid, TextField, Typography } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';

import { floatVal, min, Nullable } from "@jamesgmarks/utilities";

import { ICredit } from "../../../interfaces/ICredit";
import { StyledTableCell, StyledTableRow } from "../../parts/mui/StyledTables";
import { toDollarAmount } from "../../../app-utils";
import { getPartsFromIsoString } from "../../../app-utils/helpers";
import { roundFloatToCents } from "@hydra/internal";

interface ICreditLineProps {
  credit: ICredit;
  allowEdits: boolean;
  onChange: (credit: ICredit) => void;
  removeFn: () => void;
  amountOverride: Nullable<number>;
  setAmountOverride: Dispatch<SetStateAction<Nullable<number>>>;
  descriptionOverride: Nullable<string>;
  setDescriptionOverride: Dispatch<SetStateAction<Nullable<string>>>;
}

const CreditLine = ({
  credit,
  allowEdits,
  onChange,
  removeFn,
  amountOverride,
  setAmountOverride,
  descriptionOverride,
  setDescriptionOverride,
}: ICreditLineProps) => {
  const [ description, setDescription ] = useState(credit.description);
  const [ amount, setAmount ] = useState(floatVal(credit.amount));
  const [ amountStr, setAmountStr ] = useState(credit.amount);

  const [ originalCreditAmount ] = useState(floatVal(credit.amount));

  const genSubSubtotal = (
    floatVal(credit.generatedSubscription?.proratedSubtotal ?? 0)
      + floatVal(credit.generatedSubscription?.discountAmount ?? 0)
  );

  useEffect(() => {
    onChange({
      ...credit,
      amount: `${amount}`,
      taxAmount: `${roundFloatToCents(amount * floatVal(credit.taxRate))}`,
      description: description,
    });
  }, [ amount, description ]);

  useEffect(() => {
    setDescription(credit.description);
    setAmount(floatVal(credit.amount));
  }, [ credit ]);

  useEffect(() => {
    if (amountOverride !== null) {
      const amountToSet = min([ amountOverride, floatVal(credit.amount) ]);
      setAmount(amountToSet);
      setAmountStr(`${amountToSet}`);

      setAmountOverride(null);
    }
  }, [ amountOverride, credit.amount, setAmountOverride ]);

  useEffect(() => {
    if (descriptionOverride !== null) {
      setDescription(descriptionOverride ?? '');
    }

    setDescriptionOverride(null);
  }, [ descriptionOverride, credit.description, setDescriptionOverride ]);

  return (
    <>
      <StyledTableRow customSx={{ border: '1px solid #d3d3d3' }}>
        <StyledTableCell>
          <Typography variant='body1'>
            <b>{`${credit.generatedSubscription?.subscription?.revenueStream ?? 'No Item'}`}</b>
          </Typography>
          {
            credit.invoice?.invoiceNumber
              ? <Typography><small>Invoice Number: <b>{credit.invoice.invoiceNumber}</b></small></Typography>
              : null
          }
          <Typography>
          </Typography>
          {
            credit.invoice?.invoiceDate
              ? (
                <Typography>
                  <small>
                    Invoice Date: <b>{getPartsFromIsoString(`${credit.invoice.invoiceDate}`, 'date')}</b>
                  </small>
                </Typography>
              )
              : null
          }
          <Typography variant='subtitle2'>
              Subscription ({ credit.generatedSubscription?.subscriptionId }): ${amount} / <b>${genSubSubtotal}</b>
          </Typography>
        </StyledTableCell>

        <StyledTableCell>
          <TextField
            sx={{ my: 1, fontSize: '0.7rem', backgroundColor: 'white' }}
            size='small'
            fullWidth
            multiline
            disabled={!allowEdits}
            label='Description'
            placeholder='Description...'
            value={description ?? ''}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
          {
            allowEdits
            && (
              <>
                <br />
                <Button onClick={() => { setDescriptionOverride(description); }}>
                  <small>Copy description to other lines</small>
                </Button>
              </>
            )
          }
        </StyledTableCell>

        <StyledTableCell>
          <TextField
            variant='outlined'
            size='small'
            disabled={!allowEdits}
            sx={{ backgroundColor: 'white', width: 100, mt: 1, mb: 1 }}
            label='Unit Cost'
            value={amountStr || amount}
            onBlur={({ target: { value } }) => {
              setAmount(min([ floatVal(value === '' ? '0' : value), originalCreditAmount ]));
              setAmountStr('');
            }}
            onChange={({ target: { value } }) => {
              setAmountStr(value || '0');
            }}
          />
          {
            allowEdits
            && (
              <>
                <br />
                <Button onClick={() => { setAmountOverride(amount); }}>
                  <small>Apply unit cost to other lines</small>
                </Button>
              </>
            )
          }
        </StyledTableCell>

        <StyledTableCell>
          <Grid container justifyContent={'right'}>
            <Grid item>
              <b style={{ fontSize: '1.1rem' }}>{toDollarAmount(amount)}</b>
                &nbsp;
                &nbsp;
              <Button onClick={removeFn} color='error' disabled={!!(credit.id)}>
                { !credit.id ? <CancelIcon /> : null }
              </Button>
            </Grid>
          </Grid>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

export default CreditLine;
