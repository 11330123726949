import { Alert } from "@mui/material";
import { Table } from "react-bootstrap";

import { compactArray, distinct } from "@jamesgmarks/utilities";

import { DownloadCSV } from "../../../../parts/DownloadCSV";
import { IMonthlySummaryData } from "../../../../../redux/features/reporting/IMonthlySummaryData";
import { partnerCoinStyle } from './styles';
import { roundFloatToCentsAsDollar } from "@hydra/internal";

export const MonthlyRevenueComparison = ({
  currencyCode,
  monthlySummary,
}: {
  currencyCode: string,
  monthlySummary: IMonthlySummaryData,
}) => {
  const perStreamSummary = monthlySummary?.perStreamSummary;
  const lastMonthPerStreamSummary = monthlySummary?.lastMonthPerStreamSummary;

  const lastMonthAvailable = (!!lastMonthPerStreamSummary);

  let lastMonthSubtotal = 0;
  let thisMonthSubtotal = 0;

  return (
    <>
      {!lastMonthAvailable && <Alert severity="error">Data for previous month unavailable</Alert>}
      <DownloadCSV filename="rev_summary">
        <Table striped bordered hover variant="dark" size="sm">
          <thead>
            <tr>
              <th>Item</th>
              <th>Last Month</th>
              <th>This Month</th>
              <th>Variance</th>
            </tr>
          </thead>
          <tbody>
            {distinct(compactArray([
              ...Object.keys(perStreamSummary[currencyCode] ?? {}),
              ...Object.keys(lastMonthPerStreamSummary?.[currencyCode] ?? {}),
            ]))
              .filter(itemLabel => (`${itemLabel}` ?? '').toLowerCase() !== 'deposit')
              .sort()
              .map((itemLabel, i) => {
                const itemTotal = perStreamSummary?.[currencyCode]?.[itemLabel];
                const lastMonthItemTotal = lastMonthPerStreamSummary?.[currencyCode]?.[itemLabel];
                const itemSubtotal = itemTotal?.subTotal ?? 0;
                const lastMonthItemSubtotal = lastMonthItemTotal?.subTotal ?? 0;
                const variance = itemSubtotal - lastMonthItemSubtotal;

                thisMonthSubtotal += itemSubtotal;
                lastMonthSubtotal += lastMonthItemSubtotal;
                // addItemToRevenueTotal(revenueTotal, itemTotal);
                return (
                  <tr key={i}>
                    <td>
                      {
                        (itemTotal ?? lastMonthItemTotal)
                          .itemName
                          .slice((itemTotal ?? lastMonthItemTotal).itemName.startsWith('*') ? 1 : 0)
                      }
                      {
                        (itemTotal ?? lastMonthItemTotal)
                          .itemName
                          .startsWith('*')
                          ? <span style={partnerCoinStyle}>partner invoiced</span>
                          : ''
                      }
                    </td>
                    <td>{roundFloatToCentsAsDollar(lastMonthItemSubtotal)}</td>
                    <td>{roundFloatToCentsAsDollar(itemSubtotal)}</td>
                    <td>{roundFloatToCentsAsDollar(variance)}</td>
                  </tr>
                );
              })}
          </tbody>
          <tfoot>
            <tr>
              <th>
                Totals
              </th>
              <td>{roundFloatToCentsAsDollar(lastMonthSubtotal)}</td>
              <td>{roundFloatToCentsAsDollar(thisMonthSubtotal)}</td>
              <td>{roundFloatToCentsAsDollar(thisMonthSubtotal - lastMonthSubtotal)}</td>
            </tr>
          </tfoot>
        </Table>
      </DownloadCSV>
    </>
  );
};